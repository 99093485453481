body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.field-card {
  margin: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  border-color: black;
  border-width: 4px;
}

.add-logout-button {
  margin: 5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 20pt;
  font-weight: bolder;
  border-radius: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  display: block;
  color: black;
}

.delete-button {
  margin: 5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 16pt;
  font-weight: bolder;
  border-radius: 4px;
  padding: 10px;
  width: 50%;
  background-color: white;
  color: red;
  display: block;
  width: 100%;
}

.logout-button {
  margin: 5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 20pt;
  font-weight: bolder;
  border-radius: 4px;
  padding: 10px;
  width: 50%;
  background-color:lightskyblue;
  display: block;
  width: 100%;
}

.title-class {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 18pt;
  margin: 2px;
  margin-right: 10px;
  padding-right: 10px;
  padding: 2px;
}